import React from 'react';
import CoordinatorsPerson from "./CoordinatorsPerson";



const Coordinators = () => {
    return <div className="coordinators">
        <div className="content">
            <CoordinatorsPerson id='1'/>
            <CoordinatorsPerson id='2'/>
        </div>
    </div>
};

export default Coordinators;
